<template>
  <div class="box">
     <router-link
        v-if="$can('admin/branch-shop/create')"
        :to="{ path: 'store/create' }">
        <el-button type="primary" size="medium">
          新建店铺
        </el-button>
      </router-link>
    <div
      class="box-toolbar">
      <div class="box-item">
         <el-input
          placeholder="请输入店铺名关键字"
          v-model="bsName"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入地址关键字"
          v-model="bsAddress"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
    height="70vh"
    v-loading="loading" border :stripe="true" :data="lists">
      <!-- <el-table-column
        width="90"
        class-name="text-mono"
        prop="id"
        label="Id">
      </el-table-column> -->
      <el-table-column
        width="300px"
        prop="bsName"
        label="店铺名称">
      </el-table-column>
      <el-table-column
        width="300px"
        prop="bsAddress"
        label="店铺地址">
      </el-table-column>
      <el-table-column
        width="180"
        prop="bsPosition"
        label="店铺坐标">
      </el-table-column>
       <el-table-column
        width="150"
        prop="bsPhone"
        label="联系方式">
      </el-table-column>
       <el-table-column
        width="150"
        prop="bsStatus"
        label="店铺状态">
       <template slot-scope="scope">
                    <el-button
                        plain
                        size='mini'
                        type="primary"
                        v-if="scope.row.bsStatus == 1"
                    >营业中</el-button>
                    <el-button
                        plain
                        size="mini"
                        type="warning"
                        v-if="scope.row.bsStatus == 2"
                    >休息</el-button>
                    <el-button
                        plain
                        size="mini"
                        type="danger"
                        v-if="scope.row.bsStatus == 3"
                    >停业</el-button>
                </template>
      </el-table-column>
       <el-table-column
        width="150"
        prop="created_at"
        label="建立时间">
      </el-table-column>
       <el-table-column fixed="right" width="190" label="操作">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button plain size="mini" type="primary">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="$can('admin/branch-shop/edit')" @click.native="handleModelEdit(scope.row)">
                编辑
              </el-dropdown-item>
              <el-dropdown-item v-if="$can('admin/branch-shop/delete')" @click.native="handleModelDelete(scope.row)">
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
     <page
    :pageSize="limit"
    :count="count"
    @change="pageChange"
    @changeSize="changeSize"
    >
    </page>
  </div>
</template>

<script>
import StoreService from '@admin/services/StoreService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  components: {
    Page
  },
  data () {
    return {
      loading: true,
      function: function (param) {
        return StoreService.all(param)
      },
      delete (param) {
        return StoreService.delete(param)
      },
      bsName: '',
      bsAddress: ''
    }
  },
  mixins: [pagination],
  methods: {
    // 获取数据
    async searchGo () {
      this.page = 1
      let param = {
        bsName: this.bsName,
        bsAddress: this.bsAddress
      }
      this.where = param
      await this.getList(param)
    }
  }
}
</script>
