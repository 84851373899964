import Vue from 'vue'

const StoreService = {
  all (query) {
    return Vue.http.get('branch-shop/index', query)
  },

  delete (id) {
    return Vue.http.delete('branch-shop/delete', id)
  },

  create (store = null) {
    const endpoint = 'branch-shop/create'

    if (store === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, store)
  },

  edit (id, user = null) {
    if (user === null) {
      return Vue.http.get('branch-shop/view', { id: id })
    }

    return Vue.http.put('branch-shop/update', user, { id: id })
  },
  allSetList (query) {
    return Vue.http.get('store/setting', query)
  },
  setWm (id, form = null) {
    if (form === null) {
      return Vue.http.get('store/wm', { id: id })
    }

    return Vue.http.put('store/update', form, { id: id })
  },
  // 同步数据
  tbData () {
    return Vue.http.get('store/tb')
  }
}

export default StoreService
